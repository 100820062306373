import React from "react";

export default function TextDivider({ text, margin = "m-0" }) {
  return (
    <div className={`${margin} d-flex align-items-center`}>
      <div className="flex-grow-1">
        <hr className="my-2" />
      </div>
      <span className="mx-2"> {text} </span>
      <div className="flex-grow-1">
        <hr className="my-2" />
      </div>
    </div>
  );
}
