import { React, useRef, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDocument } from "../../hooks/useDocument";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Modal } from "react-bootstrap";
import { useUpdateTeam } from "../../hooks/useUpdateTeam";
import Cropper from "react-easy-crop";
import { useFirestore } from "../../hooks/useFirestore";
import Members from "../../components/teamProfile/Members";
import Spacer from "../../components/spacer/spacer";
import { useUpdateProfile } from "../../hooks/useUpdateProfile";
import TextDivider from "../../components/textDivider/TextDivider";
import { useNavigate } from "react-router";
import { timestamp } from "../../config/config";
import { ToastContainer, toast } from "react-toastify";

//FEAT add team discount codes?

export default function TeamProfile({ setLoading }) {
  const [isJoining, setIsJoining] = useState(false);
  const { user, authIsReady } = useAuthContext();
  const [image, setImage] = useState(null);
  const [cropModalShow, setCropModalShow] = useState(false);
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [imageType, setImageType] = useState(null);
  const [aspect, setAspect] = useState(null);

  let teamId = useParams().id;

  const [teamName, setTeamName] = useState("");
  const [teamBio, setTeamBio] = useState("");
  const [estDate, setEstDate] = useState(0);
  const [location, setLocation] = useState("");
  const [friendlyUrl, setFriendlyUrl] = useState("");

  const { error, isPending, crop, setCrop, zoom, setZoom, onCropComplete, updateAvatar, updateBanner, updateTeamProfile } =
    useUpdateTeam();
  const { updateProfile } = useUpdateProfile();
  const { softDeleteDocument, updateDocument } = useFirestore("teams");

  const { document: allTeams } = useDocument("aggregatedData", "teams");
  const { document: allEvents } = useDocument("aggregatedData", "events");
  teamId = allTeams?.teams?.find((team) => team.friendlyUrl === teamId || team.id === teamId)?.id || teamId;

  const { document: teamData, isPending: teamIsPending } = useDocument("teams", teamId);
  const navigate = useNavigate();

  useEffect(() => {
    if (teamIsPending) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [teamIsPending]);

  document.title = `OtterBox - ${teamData?.teamName}`;

  const hiddenFileInput = useRef(null);
  const hiddenFileInputBanner = useRef(null);

  const handleFileSelector = (e) => {
    document.getElementById("profileFileSelector").value = null;
    hiddenFileInput.current.click();
  };
  const handleBannerSelector = (e) => {
    document.getElementById("bannerFileSelector").value = null;
    hiddenFileInputBanner.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      setImageType("profile");
      setCropModalShow(true);
    }
  };
  const handleFileChangeBanner = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      setImageType("banner");
      setCropModalShow(true);
    }
  };

  useEffect(() => {
    if (imageType === "profile") {
      setAspect(1);
    } else if (imageType === "banner") {
      setAspect(2.5 / 1);
    }
  }, [imageType]);

  useEffect(() => {
    if (teamData?.admins?.includes(user?.uid) && teamData?.pendingMembers?.length > 0) {
      toast.info("You have pending team member requests");
    }
  }, [teamData]);

  const handleToggleEditProfileModal = () => {
    setTeamName(teamData?.teamName || "");
    setTeamBio(teamData?.teamBio || "");
    setEstDate(teamData?.estDate || "");
    setLocation(teamData?.location || "");
    setFriendlyUrl(teamData?.friendlyUrl || "");
    setShowEditTeamModal(!showEditTeamModal);
  };

  const handleToggleCropModal = () => {
    setCropModalShow(!cropModalShow);
  };

  const handleConfirmCrop = () => {
    handleToggleCropModal();
    if (imageType === "profile") {
      updateAvatar(image, teamId);
    } else if (imageType === "banner") {
      updateBanner(image, teamId);
    }
  };

  const handleJoinTeam = () => {
    setIsJoining(true);
    setTimeout(() => setIsJoining(false), 5000);
    const currentPendingTeams = user.profileData.pendingTeams || [];
    let update = { pendingTeams: [...currentPendingTeams, teamId] };
    updateProfile({ update });
    toast.success("Team join request sent! Awaiting confirmation from a team admin.");
  };

  const handleLeaveTeam = () => {
    console.log(teamData);
    if (teamData?.admins?.includes(user.uid)) {
      toast.warning("You are an admin. Please remove yourself as an admin before leaving the team.");
      return;
    }
    const confirmLeave = window.confirm("Are you sure you want to leave the team?");
    if (!confirmLeave) return;
    let update = { ...teamData };
    if (teamData?.captains?.includes(user.uid)) {
      const currentCaptains = teamData?.captains || [];
      const newCaptains = currentCaptains.filter((captain) => captain !== user.uid);
      update = { ...update, captains: newCaptains };
    }
    if (teamData?.coCaptains?.includes(user.uid)) {
      const currentCoCaptains = teamData?.coCaptains || [];
      const newCoCaptains = currentCoCaptains.filter((coCaptain) => coCaptain !== user.uid);

      update = { ...update, coCaptains: newCoCaptains };
    }
    const currentMembers = teamData?.members || [];
    const updatedMembers = currentMembers.filter((memberId) => memberId !== user.uid);
    update = {
      ...update,
      members: updatedMembers,
      memberChanges: [user.uid],
    };
    updateDocument(teamData?.id, update);

    if (user.profileData?.primaryTeamId === teamId) {
      const update = { primaryTeamId: null };
      updateProfile({ update });
    }

    toast.success("You have left the team");
  };

  const handleUpdateTeamProfile = () => {
    if (!teamName) {
      toast.warning("Team name is required");
      return;
    }
    if (teamName.length > 30) {
      toast.warning("Team name is too long. Max 30 characters.");
      return;
    }

    if (!friendlyUrl) {
      toast.warning("A friendly URL is required");
      return;
    }

    // Check if team name already exists
    const oldTeamName = teamData?.teamName;
    if (oldTeamName !== teamName) {
      const teamExists = allTeams?.teams?.some((t) => t.teamName === teamName);
      if (teamExists) {
        toast.warning("Team name already exists, please choose a different name.");
        return;
      }
    }

    const oldFriendlyUrl = teamData?.friendlyUrl;
    if (oldFriendlyUrl !== friendlyUrl) {
      const friendlyUrlExists = allTeams?.teams?.some((t) => t.friendlyUrl === friendlyUrl);
      if (friendlyUrlExists) {
        toast.warning("Friendly Url already exists, please choose a different one.");
        return;
      }

      // Check if friendlyUrl contains only letters and numbers
      if (!/^[a-zA-Z0-9]+$/.test(friendlyUrl)) {
        toast.warning("Friendly URL can only contain letters and numbers.");
        return;
      }
    }

    const update = {
      teamName,
      teamBio,
      estDate,
      location,
      friendlyUrl,
    };
    updateTeamProfile(update, teamId);
    toast.success("Team profile updated");
    handleToggleEditProfileModal();
  };

  const handleDeleteTeam = () => {
    if (window.confirm("Are you sure you wish to delete this team?")) {
      const update = {
        teamName: `${teamData?.teamName} (deleted)`,
        deleted: true,
        deletedAt: timestamp.now(),
        deletedBy: user.uid,
      };
      updateDocument(teamData?.id, update);
      // softDeleteDocument(teamId);
    }
  };

  // Not sure why i blocked out non-logged in users from seeing the team profile?....
  // if (!user) {
  //   return (
  //     <div className="row mx-1">
  //       <Spacer height="24px" />
  //       <div className="text-center card p-3">
  //         <h1>401.</h1>
  //         <h3>You must be logged in to view this team.</h3>
  //       </div>
  //       ;
  //     </div>
  //   );
  // }
  if (!teamData) {
    return (
      <div className="row mx-1">
        <Spacer height="24px" />
        <div className="text-center card p-3">
          <h1>404.</h1>
          <h3>This team does not exist.</h3>
        </div>
        ;
      </div>
    );
  }

  return (
    <>
      {/* Team Profile - {teamId ? teamId : 'Create New Team'} */}
      {/* large profile header */}
      <div className="row mx-1 d-none d-sm-block">
        {teamData?.deleted && (
          <>
            <Spacer height="24px" />
            <div className="alert alert-danger" role="alert">
              This team has been deleted so cannot be found on the roster or joined. Speak to an admin to re-claim.
            </div>
          </>
        )}
        {!teamData?.banner && <Spacer height="104px" />}
        <div className="position-relative p-0 pb-3 mt-2">
          {teamData?.banner && (
            <img src={teamData.banner} className="w-100 p-0" style={{ marginBottom: "-64px", borderRadius: "8px" }} />
          )}
          {teamData?.admins?.includes(user?.uid) && !teamData?.deleted && (
            <>
              <button
                className="btn btn-secondary btn-sm position-absolute bottom-0 end-0 mt-2 me-2 mb-2 border"
                style={{ zIndex: 1 }}
                onClick={handleBannerSelector}
              >
                <i className="bi bi-camera-fill my-3"></i> Update Cover Photo
              </button>
              <input
                type="file"
                id="bannerFileSelector"
                ref={hiddenFileInputBanner}
                className="form-control mb-1 d-none"
                onChange={handleFileChangeBanner}
              />
            </>
          )}
        </div>
        <div className="card p-3">
          <div
            className="d-flex"
            style={{
              marginBottom: "-100px",
            }}
          >
            <div className="flex-shrink-1 profile-image-changer me-3 translate-middle-y">
              <img
                className="profile-image position-relative"
                src={teamData?.avatar || "https://placehold.co/150?text=OtterBox"}
              />

              {teamData?.admins?.includes(user?.uid) && !teamData?.deleted && (
                <>
                  <button
                    className="btn btn-secondary position-relative bottom-0 start-100 translate-bottom-right rounded-circle btn-lg"
                    onClick={handleFileSelector}
                  >
                    <i className="bi bi-camera-fill fs-2 my-3"></i>
                  </button>
                  <div>
                    <input
                      type="file"
                      id="profileFileSelector"
                      ref={hiddenFileInput}
                      className="form-control mb-1 d-none"
                      onChange={handleFileChange}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="flex-grow-1">
              <h1 className="d-inline">{teamData?.teamName} </h1>{" "}
              {teamData?.admins?.includes(user?.uid) && !teamData?.deleted && (
                <i
                  className="bi bi-pencil-square fs-4 text-primary me-1 d-inline"
                  onClick={() => handleToggleEditProfileModal()}
                ></i>
              )}
              <h4 className="text-muted">
                {(teamData?.members?.length ?? 0) === 1
                  ? `${teamData?.members?.length} member`
                  : `${teamData?.members?.length ?? 0} members`}
              </h4>
              <h5 className="text-muted">
                {teamData?.boosted?.length > 0 && (
                  <>
                    <i className="bi bi-lightning-fill text-warning"></i>{" "}
                    {teamData?.boosted?.length > 1 ? `${teamData?.boosted?.length} boosts` : "1 boost"}
                  </>
                )}
              </h5>
              {user &&
                !teamData?.members?.includes(user?.uid) &&
                !teamData?.pendingMembers?.includes(user?.uid) &&
                !teamData?.deleted && (
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={handleJoinTeam} disabled={isJoining}>
                      {isJoining ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        <i className="bi bi-person-plus-fill me-1"></i>
                      )}
                      {isJoining ? " Sending request..." : "Join Team"}
                    </button>
                  </div>
                )}
              {teamData?.pendingMembers?.includes(user?.uid) && (
                <div className="d-flex justify-content-end">
                  <button className="btn btn-secondary disabled">
                    <i className="bi bi-person-plus-fill me-1"></i>Pending
                  </button>
                </div>
              )}
              {teamData?.members?.includes(user?.uid) && (
                <div className="d-flex justify-content-end">
                  <button className="btn btn-secondary" onClick={handleLeaveTeam}>
                    <i className="bi bi-person-dash-fill me-1"></i>Leave Team
                  </button>
                </div>
              )}
              {/* {teamData?.admins?.includes(user.uid) && (
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-secondary" onClick={handleToggleEditProfileModal}>
                      <i className="bi bi-pencil-square me-1"></i>Update Profile
                    </button>
                  </div>
                )} */}
            </div>
          </div>
        </div>
      </div>
      {/* small profile header */}
      <div className="row mx-1 d-sm-none">
        {!teamData?.banner && <Spacer height="62px" />}
        <div className="position-relative p-0 pb-3 mt-2">
          {teamData?.banner && (
            <img src={teamData.banner} className="w-100 p-0" style={{ marginBottom: "-34px", borderRadius: "8px" }} />
          )}
          {teamData?.admins?.includes(user?.uid) && !teamData?.deleted && (
            <>
              <button
                className="btn btn-secondary btn-sm position-absolute bottom-0 end-0 mt-2 me-2 mb-2 border"
                style={{ zIndex: 1 }}
                onClick={handleBannerSelector}
              >
                <i className="bi bi-camera-fill my-3"></i> Update Cover Photo
              </button>
              <input
                type="file"
                id="bannerFileSelector"
                ref={hiddenFileInputBanner}
                className="form-control mb-1 d-none"
                onChange={handleFileChangeBanner}
              />
            </>
          )}
        </div>
        <div className="card p-3" style={{ height: "250px" }}>
          <div className=" translate-middle-y" style={{ marginTop: "60px" }}>
            <div className="flex-shrink-1 profile-image-changer-sm me-3">
              <img
                className="profile-image-sm position-relative"
                src={teamData?.avatar || "https://placehold.co/150?text=OtterBox"}
              />

              {teamData?.admins?.includes(user?.uid) && !teamData?.deleted && (
                <>
                  <button
                    className="btn btn-secondary position-relative bottom-0 start-100 translate-bottom-right rounded-circle btn-lg"
                    onClick={handleFileSelector}
                  >
                    <i className="bi bi-camera-fill fs-2 my-3"></i>
                  </button>
                  <div>
                    <input
                      type="file"
                      id="profileFileSelector"
                      ref={hiddenFileInput}
                      className="form-control mb-1 d-none"
                      onChange={handleFileChange}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="">
              <h1>
                {teamData?.teamName}
                {teamData?.admins?.includes(user?.uid) && !teamData?.deleted && (
                  <i
                    className="bi bi-pencil-square fs-4 text-primary me-1 d-inline"
                    onClick={() => handleToggleEditProfileModal()}
                  ></i>
                )}
              </h1>
              <h4 className="text-muted">
                {teamData?.members?.length === 1 ? `${teamData?.members?.length} member` : `${teamData?.members?.length} members`}
              </h4>
              <h5 className="text-muted">
                {teamData?.boosted?.length > 0 && (
                  <>
                    <i className="bi bi-lightning-fill text-warning"></i>{" "}
                    {teamData?.boosted?.length > 1 ? `${teamData?.boosted?.length} boosts` : "1 boost"}
                  </>
                )}
              </h5>
              {user &&
                !teamData?.members?.includes(user?.uid) &&
                !teamData?.pendingMembers?.includes(user?.uid) &&
                !teamData?.deleted && (
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={handleJoinTeam} disabled={isJoining}>
                      {isJoining ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        <i className="bi bi-person-plus-fill me-1"></i>
                      )}
                      {isJoining ? " Sending request..." : "Join Team"}
                    </button>
                  </div>
                )}
              {teamData?.pendingMembers?.includes(user?.uid) && (
                <div className="d-flex justify-content-end">
                  <button className="btn btn-secondary disabled">
                    <i className="bi bi-person-plus-fill me-1"></i>Pending
                  </button>
                </div>
              )}
              {teamData?.members?.includes(user?.uid) && (
                <div className="d-flex justify-content-end">
                  <button className="btn btn-secondary" onClick={handleLeaveTeam}>
                    <i className="bi bi-person-dash-fill me-1"></i>Leave Team
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-0 mb-1 mt-2">
        <div className="col-xs-12 col-md-6 p-1">
          <div className="card p-3 h-100">
            <h3>
              About{" "}
              {teamData?.admins?.includes(user?.uid) && !teamData?.deleted && (
                <i
                  className="bi bi-pencil-square fs-4 text-primary me-1 d-inline"
                  onClick={() => handleToggleEditProfileModal()}
                ></i>
              )}
            </h3>
            <p>{teamData?.teamBio || "Nothing to say"}</p>
            {teamData?.estDate && (
              <p>
                <b>Team Formation:</b> {teamData?.estDate || "1970"}
              </p>
            )}
            {teamData?.location && (
              <p>
                <b>Location:</b> {teamData?.location || "nowhere"}
              </p>
            )}
          </div>
        </div>
        <div className="col-xs-12 col-md-6 p-1">
          <div className="card p-3 h-100">
            <h3>Our Upcoming Events</h3>
            {allEvents &&
            allEvents.events.filter(
              (event) =>
                new Date(event.endDate) > new Date() &&
                teamData?.members?.filter((memberId) => event.players.going.some((player) => player.id === memberId)).length > 0,
            ).length === 0 ? (
              <div className="text-muted">No team members have registered for any upcoming events yet.</div>
            ) : (
              allEvents &&
              allEvents.events
                .filter(
                  (event) =>
                    new Date(event.endDate) > new Date() &&
                    teamData?.members?.filter((memberId) => event.players.going.some((player) => player.id === memberId)).length >
                      0,
                )
                .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
                .map((event) => {
                  return (
                    <div
                      onClick={() => navigate(`/events/${event.id}`)}
                      className="card p-1 mb-1 ps-3"
                      role="button"
                      key={event.id}
                    >
                      <div className="d-flex w-100">
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                          {event.privateEvent && <>Private Event</>}
                          {!event.privateEvent && <>{event.eventName}</>}
                        </div>
                        <small className="ms-auto text-muted" style={{ minWidth: "fit-content" }}>
                          {new Date(event.startDate).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "numeric",
                            year: "2-digit",
                          })}{" "}
                          |{" "}
                          <span className="text-success ">
                            {`
                          ${
                            teamData?.members?.filter((memberId) => event.players.going.some((player) => player.id === memberId))
                              .length
                          } 
                          `}
                            <i className="bi bi-check-circle-fill text-success"></i>
                          </span>{" "}
                          |{" "}
                          <span className="text-warning">
                            {` ${teamData?.members?.filter((memberId) => event.players.interested.includes(memberId)).length}`}
                            <i className="bi bi-question-circle-fill ms-1"></i>
                          </span>
                        </small>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
        </div>
      </div>

      <div className="row m-1">{teamData && <Members team={teamData} />}</div>

      <Modal show={cropModalShow} backdrop="static" onHide={handleToggleCropModal} id="cropModal">
        <Modal.Header closeButton />
        <Modal.Body style={{ height: "500px" }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <button className="btn btn-primary" onClick={() => handleConfirmCrop()}>
            Upload
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditTeamModal} backdrop="static" onHide={handleToggleEditProfileModal} id="editProfileModal">
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            Edit Profile
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="mb-3">
              <div className="input-group mb-3">
                <span className="input-group-text">Team Name:</span>
                <input
                  type="text"
                  className="form-control"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  placeholder="Team Name"
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text">Bio:</span>
                <textarea
                  type="text"
                  className="form-control"
                  value={teamBio}
                  onChange={(e) => setTeamBio(e.target.value)}
                  placeholder="Nothing to say"
                  rows="3"
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text">Location:</span>
                <input
                  type="text"
                  className="form-control"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder="Location"
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text">Established:</span>
                <input
                  type="text"
                  className="form-control"
                  value={estDate}
                  onChange={(e) => setEstDate(e.target.value)}
                  placeholder="Established"
                />
              </div>
            </div>
            <TextDivider text="Page Settings" />
            <div className="input-group mb-0">
              <span className="input-group-text">Friendly URL:</span>
              <input
                type="text"
                className="form-control"
                value={friendlyUrl}
                onChange={(e) => setFriendlyUrl(e.target.value)}
                placeholder="friendly url"
              />
            </div>
            <div className="mb-3 ms-1 mt-1">https://otterbox.makbox.co.uk/team/{friendlyUrl}</div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="allowNonMembersToViewComments"
                checked={null}
                onChange={(e) => null}
              />
              <label className="form-check-label" htmlFor="allowNonMembersToViewComments">
                Non-team members can view comments
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="allowNonMembersToViewComments"
                checked={null}
                onChange={(e) => null}
              />
              <label className="form-check-label" htmlFor="allowNonMembersToViewComments">
                Non-team members can post comments
              </label>
            </div>
            <hr />
            <div className="d-flex justify-content-center">
              <button className="btn btn-primary w-100" type="button" onClick={() => handleUpdateTeamProfile()}>
                Save
              </button>
            </div>
            <hr />
            <button className="btn btn-danger w-100" onClick={handleDeleteTeam}>
              Delete Team
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={handleToggleEditProfileModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
