// import RecipeList from '../../components/RecipeList'
import { React, projectFirestore } from "../../config/config";
import Profile from "../profile/profile";
import NewsFeed from "../newsFeed/newsFeed";
import { Routes, Route } from "react-router";
import { useEffect } from "react";
import { useState } from "react";
import Events from "../events/events";
import { useAuthContext } from "../../hooks/useAuthContext";
import Login from "../login/Login";
import Signup from "../signup/Signup";
import EventDetails from "../eventDetails/eventDetails";
import Roster from "../roster/roster";
import TeamProfile from "../teamProfile/teamProfile";
import Spacer from "../../components/spacer/spacer";

//FEAT create contact admin page/form

export default function Home({ setLoading }) {
  const { user, authIsReady } = useAuthContext();
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);
  const [savedDate, setSavedDate] = useState("");

  document.title = "OtterBox";
  if (!user) {
    return (
      <div className="col-12 col-md-8 offset-0 offset-md-2 mt-5 pt-4">
        <Routes>
          <Route path="/signup" element={<Signup setLoading={setLoading} />} />
          <Route path="/team/:id" element={<TeamProfile setLoading={setLoading} />} />
          <Route path="/team/:friendlyUrl" element={<TeamProfile setLoading={setLoading} />} />
          <Route path="/events" element={<Events setLoading={setLoading} />} />
          <Route path="/events/:id" element={<EventDetails setLoading={setLoading} />} />
          <Route path="/*" element={<Login setLoading={setLoading} />} />
        </Routes>
      </div>
    );
  }
  if (!user.profileData) {
    return (
      <div className="col-sm-10 offset-sm-1 offset-md-1 col-md-7 offset-lg-0 col-lg-8 align-self-center p-0 card-clear">
        <Spacer />
        <Routes>
          <Route path="/*" element={<Profile setLoading={setLoading} />} />
        </Routes>
      </div>
    );
  }
  return (
    <>
      <div className="col-sm-10 offset-sm-1 offset-md-1 col-md-7 offset-lg-0 col-lg-8 align-self-center p-0 card-clear">
        <Spacer />
        <Routes>
          <Route path="/" element={<NewsFeed setLoading={setLoading} />} />
          <Route path="/login" element={<NewsFeed setLoading={setLoading} />} />
          <Route path="/roster" element={<Roster setLoading={setLoading} />} />
          <Route path="/roster/teams" element={<Roster setLoading={setLoading} filter={"teams"} />} />
          <Route path="/roster/players" element={<Roster setLoading={setLoading} filter={"players"} />} />
          <Route path="/roster/:id" element={<Profile setLoading={setLoading} />} />
          <Route path="/signup" element={<Profile setLoading={setLoading} newUser={user} />} />
          <Route path="/team/:id" element={<TeamProfile setLoading={setLoading} />} />
          <Route path="/team/:friendlyUrl" element={<TeamProfile setLoading={setLoading} />} />
          <Route path="/team/new" element={<TeamProfile setLoading={setLoading} />} />
          <Route path="/profile/:id" element={<Profile setLoading={setLoading} />} />
          <Route path="/events" element={<Events setLoading={setLoading} />} />
          <Route path="/events/:id" element={<EventDetails setLoading={setLoading} />} />
          <Route path="/*" element={<NewsFeed setLoading={setLoading} />} />
          {/* <Route path="/settings" element={<Settings />} /> */}
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </>
  );
}
