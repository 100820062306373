import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import TextDivider from "../textDivider/TextDivider";

export default function LogisticsOverview({
  team,
  teamPlayers,
  teamPlayersGoing,
  teamPlayersInterested,
  event,
  showLogisticsOverviewModal,
  setShowLogisticsOverviewModal,
  userList,
}) {
  const [numCars, setNumCars] = useState(0);
  const [numTents, setNumTents] = useState(0);
  const [numCaravans, setNumCaravans] = useState(0);
  const [players, setPlayers] = useState([]);
  const [goingPlayers, setGoingPlayers] = useState([]);
  const [interestedPlayers, setInterestedPlayers] = useState([]);

  useEffect(() => {
    if (event) {
      const { players } = event;
      const filteredPlayersGoing = players?.going.filter((player) =>
        teamPlayersGoing.some((teamPlayer) => teamPlayer.id === player.id),
      );
      const filteredPlayersInterested = players?.interested.filter((playerId) =>
        teamPlayersInterested.some((teamPlayer) => teamPlayer.id === playerId),
      );

      setPlayers(players);
      setGoingPlayers(filteredPlayersGoing);
      setInterestedPlayers(filteredPlayersInterested);
      setNumCars(filteredPlayersGoing.filter((player) => player.transport?.toLowerCase() === "driving").length);
      setNumTents(filteredPlayersGoing.filter((player) => player.sleeping?.toLowerCase() === "tent").length);
      setNumCaravans(filteredPlayersGoing.filter((player) => player.sleeping?.toLowerCase() === "caravan").length);
    }
  }, [event, teamPlayersGoing, teamPlayersInterested]);
  return (
    <Modal
      id="logisticsOverviewModal"
      show={showLogisticsOverviewModal}
      fullscreen
      onHide={() => setShowLogisticsOverviewModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Logistics Overview - {team?.label}, {event?.eventName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextDivider text={"Event Details"} />
        <div className="p-1 my-2">
          <div>Players going: {goingPlayers?.length}</div>
          <div>Players interested: {interestedPlayers?.length}</div>
        </div>
        <TextDivider text={"Arrival Times"} />

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Day</th>
                <th scope="col">AM</th>
                <th scope="col">PM</th>
                <th scope="col">Eve</th>
              </tr>
            </thead>
            <tbody>
              {["Friday", "Saturday", "Sunday"].map((day) => (
                <tr key={day}>
                  <th scope="row">{day}</th>
                  {["AM", "PM", "Evening"].map((time) => {
                    return (
                      <td key={time}>
                        {goingPlayers?.filter((player) => player.arrival === day && player.arrivalSpecific === time).length}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <TextDivider text={"Space required"} />
        <div className=" p-1">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Cars</th>
                  <th scope="col">Tents</th>
                  <th scope="col">Caravans</th>
                  <th scope="col">Extras</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{numCars}</td>
                  <td>{numTents}</td>
                  <td>{numCaravans}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {Object.keys(
                      goingPlayers?.reduce((acc, player) => {
                        player?.extras?.forEach((extra) => {
                          if (acc[extra]) {
                            acc[extra] += 1;
                          } else {
                            acc[extra] = 1;
                          }
                        });
                        return acc;
                      }, {}),
                    ).map((key) => (
                      <div className="pe-1" key={key}>
                        {key}:{" "}
                        {
                          goingPlayers.reduce((acc, player) => {
                            player?.extras?.forEach((extra) => {
                              if (acc[extra]) {
                                acc[extra] += 1;
                              } else {
                                acc[extra] = 1;
                              }
                            });
                            return acc;
                          }, {})[key]
                        }
                      </div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <TextDivider text={"Paint Orders"} />
        <div className="p-1">
          <div className="">
            Total Paint:{" "}
            {goingPlayers
              ?.reduce(
                (total, player) =>
                  total + (player.paint ? player.paint.match(/\d+/g).reduce((acc, curr) => acc + parseInt(curr, 10), 0) : 0),
                0,
              )
              .toString()}
          </div>
        </div>
        <TextDivider text={"Detailed List"} />
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Paint</th>
                <th scope="col">Arrival</th>
                <th scope="col">Sleeping</th>
                <th scope="col">Transport</th>
                <th scope="col">Extras</th>
              </tr>
            </thead>
            <tbody>
              {goingPlayers.map((player) => {
                const user = userList?.users?.find((user) => user.id === player.id);
                return (
                  <tr key={player.id}>
                    <td>
                      {user?.profileData?.firstName || ""} {user?.profileData?.surName || ""}
                    </td>
                    <td>{player.paint || 0}</td>
                    <td>
                      {player.arrival} - {player.arrivalSpecific}
                    </td>
                    <td>{player.sleeping || ""}</td>
                    <td>{player.transport || ""}</td>
                    <td>
                      {Object.keys(player.extras || {}).map((key) => (
                        <div style={{ whiteSpace: "nowrap" }} key={key}>
                          {player.extras[key]}
                        </div>
                      ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}
