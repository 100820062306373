import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import "firebase/messaging";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBsNbt9ZHoaCz4eYgTUkrFj_tMVEAYytjg",
  authDomain: "otterbox-3abf3.firebaseapp.com",
  databaseURL: "https://otterbox-3abf3-default-rtdb.firebaseio.com",
  projectId: "otterbox-3abf3",
  storageBucket: "otterbox-3abf3.appspot.com",
  messagingSenderId: "782294859593",
  appId: "1:782294859593:web:8ca5bab45ce9baba26a498",
  measurementId: "G-859CGJ657Z",
};

firebase.initializeApp(firebaseConfig);

const projectFirestore = firebase.firestore();
if (document.location.hostname === "localhost") {
  projectFirestore.useEmulator("127.0.0.1", 8080);
}
const projectAuth = firebase.auth();
if (document.location.hostname === "localhost") {
  projectAuth.useEmulator("http://127.0.0.1:9099");
}
const projectStorage = firebase.storage();
if (document.location.hostname === "localhost") {
  projectStorage.useEmulator("127.0.0.1", 9199);
}
const projectDatabase = firebase.database();
if (document.location.hostname === "localhost") {
  projectDatabase.useEmulator("127.0.0.1", 9000);
}

const analytics = firebase.analytics();

let messaging,
  messagingSupported = false;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messagingSupported = true;
  messaging.usePublicVapidKey("BDv71K7zWlD5p7yY2vkR1VpGOoUaQ21VN4grpFj054aRvfTUstFF9GIKT10603Sk2d9QzigVpkRCqwRZWvap4pU");
}

const timestamp = firebase.firestore.Timestamp;

// firebase.firestore().clearPersistence();
// firebase.firestore().enablePersistence();

export { projectFirestore, projectDatabase, analytics, projectAuth, projectStorage, timestamp, messagingSupported, messaging };
