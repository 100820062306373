import { useReducer, useEffect, useState } from "react";
import { projectFirestore, timestamp } from "../config/config";

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return { isPending: true, error: null, document: null, success: false };
    case "ADDED_DOC":
      return { document: action.payload, isPending: false, success: true, error: null };
    case "DELETED_DOC":
      return { document: null, isPending: false, success: true, error: null };
    case "SOFT_DELETED_DOC":
      return { document: action.payload, isPending: false, success: true, error: null };
    case "UPDATED_DOC":
      return { isPending: false, document: action.payload, success: true, error: null };
    case "ERROR":
      return { isPending: false, document: null, success: false, error: action.payload };
    default:
      return state;
  }
};

export const useFirestore = (collection) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);

  const ref = projectFirestore.collection(collection);

  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      dispatch(action);
    }
  };

  const addDocument = async (doc) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const createdAt = timestamp.fromDate(new Date());
      // console.log('Document to add:', doc);

      const addedDocumentRef = await ref.add({ ...doc, createdAt });
      // console.log('Document added successfully:', addedDocumentRef.id);

      const addedDocument = await addedDocumentRef.get();
      // console.log('Retrieved added document data:', addedDocument.data());

      if (!isCancelled) {
        // console.log('Dispatching ADDED_DOC action');
        dispatchIfNotCancelled({
          type: "ADDED_DOC",
          payload: { ...addedDocument.data(), id: addedDocument.id },
        });
      } else {
        // console.log('isCancelled is true, not dispatching');
      }

      return addedDocumentRef;
    } catch (error) {
      console.error("Error adding document:", error);
      dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
    }
  };

  const deleteDocument = async (id) => {
    dispatch({ type: "IS_PENDING" });

    try {
      await ref.doc(id).delete();
      dispatchIfNotCancelled({ type: "DELETED_DOC" });
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: "could not delete" });
    }
  };

  const softDeleteDocument = async (id) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const deleted = { deleted: true, deletedTime: timestamp.now() };
      const softDeletedDocument = await ref.doc(id).update(deleted);
      dispatchIfNotCancelled({ type: "SOFT_DELETED_DOC", payload: softDeletedDocument });
      return softDeletedDocument;
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
    }
  };
  const updateDocument = async (id, updates) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const updatedDocument = await ref.doc(id).update(updates);
      dispatchIfNotCancelled({ type: "UPDATED_DOC", payload: updatedDocument });
      return updatedDocument;
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
      console.log(error);
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { addDocument, softDeleteDocument, deleteDocument, updateDocument, response };
};
