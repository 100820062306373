import React from "react";
import Avatar from "../avatar/Avatar";

//TODO add primary team to card.
//TODO centrally make cards clickable for team/player profile

export default function InfoCard({ size, teamData = null, playerData = null }) {
  let user = playerData;
  let team = teamData;

  if (!teamData && !playerData) {
    user = {
      profileData: {
        firstName: "Deleted",
      },
    };
  }

  return (
    <>
      <div className="d-flex position-relative m-1">
        {user && <Avatar src={user.profileData?.photoURL} className="align-self-center" />}
        {team && <Avatar src={team.avatar} className="align-self-center" />}
        {user && size === "sm" && (
          <span className="position-absolute bottom-0 start-50 translate-middle-x badge rounded-pill bg-danger">
            {user?.profileData?.firstName?.charAt(0)}
            {user?.profileData?.surName?.charAt(0)}
          </span>
        )}
        {user && !size && (
          <span className="align-self-center ms-3">
            <div>
              {user?.profileData?.firstName} {user?.profileData?.surName}
            </div>
          </span>
        )}
        {team && !size && (
          <span className="align-self-center ms-3">
            <div>{team?.teamName}</div>
          </span>
        )}
      </div>
    </>
  );
}
