// imports
import { BrowserRouter } from "react-router-dom";
import { React, useState } from "react";
import { messagingSupported } from "./config/config";

// pages
import Home from "./pages/home/home";

//components
import Header from "./components/header/header";
import { useAuthContext } from "./hooks/useAuthContext";
import Sidebar from "./components/sidebar/Sidebar";
import NotificationSetup from "./components/notifications/Notifications";
import { ToastContainer } from "react-toastify";
import LoadingOverlay from "./components/loadingOverlay/LoadingOverlay";

function App() {
  const { authIsReady, user } = useAuthContext();
  const [loading, setLoading] = useState(true);

  console.log(messagingSupported);

  return (
    <div className="App">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter>
        <div className="container-fluid p-0">
          {authIsReady && (
            <>
              {messagingSupported && <NotificationSetup id={user?.uid} />}
              <Header />
              {loading && <LoadingOverlay />}
              <div className="row">
                {user && (
                  <>
                    <Sidebar />
                    <div className="col-md-3"></div>
                  </>
                )}
                <Home setLoading={setLoading} />
              </div>
            </>
          )}
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
