import { React, useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";
import Avatar from "../../components/avatar/Avatar";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useFirestore } from "../../hooks/useFirestore";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

//FEAT split Teams and Players into seperate parts for neatness. or come up with a better ordering method. maybe make the cards smaller so more than 4 can be seen at a time on mobile

export default function Roster({ setLoading, filter = null }) {
  document.title = "OtterBox - Roster";
  const [filteredRoster, setFilteredRoster] = useState([]); // Filtered users based on search
  const [visibleUsers, setVisibleUsers] = useState(10); // Control number of visible users
  const [playerFilter, setPlayerFilter] = useState("");
  const { document: userData, error: userError, isPending: userIsPending } = useDocument("aggregatedData", "users");
  const { document: teamData, error: teamError, isPending: teamIsPending } = useDocument("aggregatedData", "teams");
  const filteredTeams = teamData?.teams || [];
  const [showPlayers, setShowPlayers] = useState(true);
  const [showTeams, setShowTeams] = useState(true);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [error, setError] = useState(null);
  const [teamName, setTeamName] = useState("");

  const { addDocument } = useFirestore("teams");
  const { updateDocument } = useFirestore("users");

  const { user } = useAuthContext();

  const navigate = useNavigate();
  useEffect(() => {
    if (filter === "teams") {
      setShowPlayers(false);
      setShowTeams(true);
    }
    if (filter === "players") {
      setShowTeams(false);
      setShowPlayers(true);
    }
  }, [filter]);

  // Initial roster filtering for users not marked as deleted
  useEffect(() => {
    let players = [];
    let teams = [];

    if (userData && showPlayers) {
      const filteredUsers = userData.users.filter((user) => !user.deleted);
      players = filteredUsers;
    }
    if (teamData && showTeams) {
      const filteredTeams = teamData.teams.filter((team) => !team.deleted);
      teams = filteredTeams;
    }
    const concat = players.concat(teams);
    concat.sort((a, b) => b.createdAt - a.createdAt);

    const searchTerm = playerFilter.toLowerCase();
    if (searchTerm) {
      const filteredUsers = concat.filter((userOrTeam) => {
        const name =
          userOrTeam.profileData && userOrTeam.profileData.firstName ? userOrTeam.profileData.firstName.toLowerCase() : "";
        const handle = userOrTeam.profileData && userOrTeam.profileData.alias ? userOrTeam.profileData.alias.toLowerCase() : "";
        const surName =
          userOrTeam.profileData && userOrTeam.profileData.surName ? userOrTeam.profileData.surName.toLowerCase() : "";
        const teamName = userOrTeam.teamName ? userOrTeam.teamName.toLowerCase() : "";
        return (
          name.includes(searchTerm) ||
          handle.includes(searchTerm) ||
          surName.includes(searchTerm) ||
          teamName.includes(searchTerm)
        );
      });
      setFilteredRoster(filteredUsers);
    } else {
      setFilteredRoster(concat);
    }
    setLoading(false);
  }, [userData, teamData, showTeams, showPlayers, playerFilter]);

  const clearFilter = (e) => {
    e.preventDefault();
    setPlayerFilter("");
  };
  // Function to load more users when the "Load more" button is clicked
  const loadMore = () => {
    setVisibleUsers((prevVisible) => prevVisible + 10); // Load 10 more users
  };

  // Function to create a new team
  const createTeam = async (e) => {
    e.preventDefault();
    if (teamName.length === 0) {
      toast.warning("Please enter a team name");
      return;
    }
    if (teamName.length > 30) {
      toast.warning("Team name must be less than 30 characters");
      return;
    }
    if (teamData?.teams?.some((team) => !team.deleted && team.teamName === teamName)) {
      toast.warning(
        "A team with that name already exists, please choose a different name or speak to an admin to claim this team",
      );
      return;
    }
    const team = {
      teamName,
      members: [user.uid],
      admins: [user.uid],
      friendlyUrl: teamName.replace(/\s+/g, "-").toLowerCase(),
      createdAt: new Date().getTime(),
    };

    const addedDocumentRef = await addDocument(team);
    const newProfileData = {
      ...user.profileData,
      teams: user.profileData?.teams ? [...user.profileData.teams, addedDocumentRef.id] : [addedDocumentRef.id],
    };

    updateDocument(user.uid, {
      profileData: newProfileData,
    });
    setShowCreateTeamModal(false);
    if (addedDocumentRef) {
      navigate(`/team/${addedDocumentRef.id}`);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row p-0 m-0 justify-content-left">
          <div className="col-12 mt-2">
            <div className="p-3 card m-0">
              <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center">
                <div className="btn-group order-0 me-2" role="group" aria-label="Basic checkbox toggle button group">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="btncheck1"
                    autoComplete="off"
                    checked={showPlayers}
                    onChange={() => setShowPlayers(!showPlayers)}
                  />
                  <label className="btn btn-outline-primary" htmlFor="btncheck1">
                    Players
                  </label>

                  <input
                    type="checkbox"
                    className="btn-check"
                    id="btncheck2"
                    autoComplete="off"
                    checked={showTeams}
                    onChange={() => setShowTeams(!showTeams)}
                  />
                  <label className="btn btn-outline-primary" htmlFor="btncheck2">
                    Teams
                  </label>
                </div>
                <div className="input-group order-2 order-lg-1 mt-1 mt-lg-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a player or team..."
                    value={playerFilter}
                    onChange={(e) => {
                      setPlayerFilter(e.target.value);
                    }}
                  />

                  <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={clearFilter}>
                    Clear
                  </button>
                </div>
                <button
                  className="btn btn-primary ms-2 text-nowrap order-1 order-lg-2"
                  onClick={() => setShowCreateTeamModal(true)}
                >
                  Create Team
                </button>
              </div>
            </div>
          </div>

          {filteredRoster &&
            filteredRoster
              .slice(0, visibleUsers) // Show only the first `visibleUsers` items
              .map((user) => {
                return (
                  <div key={user?.id} className="col-12 col-lg-6 mt-2">
                    <div className="">
                      {user?.profileData && (
                        <Link to={`/profile/${user?.id}`} className="text-decoration-none">
                          <div className="p-3 card m-0">
                            <div className="d-flex large-avatar">
                              <div className="d-flex">
                                <Avatar src={(user.profileData && user.profileData.photoURL) || null} />
                                <div className="ms-1">
                                  <div>
                                    {user.profileData?.firstName} {user.teamName}{" "}
                                    {user.profileData?.alias && <>{`"${user.profileData?.alias}"`}</>} {user.profileData?.surName}
                                  </div>{" "}
                                  {user?.profileData?.primaryTeamId && (
                                    <span>
                                      <span className="m-1 badge bg-light text-dark">
                                        <i className="bi bi-star text-warning"></i>{" "}
                                        {filteredTeams?.find((team) => team.id === user?.profileData?.primaryTeamId)?.teamName}
                                      </span>
                                    </span>
                                  )}
                                  {user?.profileData?.teams
                                    ?.filter((teamId) => teamId !== user?.profileData?.primaryTeamId)
                                    .slice(0, 1)
                                    .map((teamId) => {
                                      const teamsWithoutPrimary = user?.profileData?.teams.filter(
                                        (teamId) => teamId !== user?.profileData?.primaryTeamId,
                                      );
                                      const teamIdIndex = Math.floor(Math.random() * teamsWithoutPrimary.length);
                                      const team = filteredTeams?.find(
                                        (team) => team.id === teamsWithoutPrimary[teamIdIndex],
                                      )?.teamName;
                                      const moreTeams = user?.profileData?.teams
                                        ?.filter((teamId) => teamId !== user?.profileData?.primaryTeamId)
                                        .slice(1).length;
                                      return (
                                        <span key={teamId}>
                                          <span className="m-1 badge bg-light text-dark">{team}</span>
                                          {moreTeams > 0 && (
                                            <span className="m-1 badge bg-light text-dark">and {moreTeams} more</span>
                                          )}
                                        </span>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      )}
                      {user?.teamName && user?.boosted?.length > 0 && (
                        <Link to={`/team/${user?.id}`} className="text-decoration-none">
                          <div
                            className="card-clear"
                            style={{
                              backgroundColor: "rgba(255,255,255,1)",
                              backgroundImage: user?.banner ? `url(${user.banner})` : undefined,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              overflow: "hidden",
                            }}
                          >
                            <div style={{ backgroundColor: "rgba(255,255, 255, 0.75)" }}>
                              <div className="p-3 card-clear m-0">
                                <div className="d-flex large-avatar">
                                  <div className="d-flex">
                                    <Avatar src={user?.avatar || "https://placehold.co/50?text=OtterBox"} />
                                    <div className="ms-1">
                                      <div>
                                        <h4>{user?.teamName}</h4>
                                        <div>
                                          {user?.members?.length === 1 ? "1 Member" : `${user?.members?.length || 0} Members`}
                                        </div>
                                        <div>{user?.location}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      )}
                      {user?.teamName && !user?.boosted && (
                        <Link to={`/team/${user?.id}`} className="text-decoration-none">
                          <div className="p-3 card m-0">
                            <div className="d-flex large-avatar">
                              <div className="d-flex">
                                <Avatar src={user?.avatar || "https://placehold.co/50?text=OtterBox"} />
                                <div className="ms-1">
                                  <div>
                                    <h4>{user?.teamName}</h4>
                                    <div>
                                      {user?.members?.length === 1 ? "1 Member" : `${user?.members?.length || 0} Members`}
                                    </div>
                                    <div>{user?.location}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                );
              })}
          {/* Load more button */}
          {filteredRoster && visibleUsers < filteredRoster.length && (
            <div className="d-flex justify-content-center">
              <button className="btn btn-primary mt-3" onClick={loadMore}>
                Load more...
              </button>
            </div>
          )}

          {/* Modal to collect team name */}
          <Modal show={showCreateTeamModal} onHide={() => setShowCreateTeamModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Create a new team</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="mb-3">
                  <label htmlFor="teamName" className="form-label">
                    Team Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="teamName"
                    onChange={(e) => setTeamName(e.target.value)}
                    value={teamName}
                    placeholder="Enter team name"
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary" onClick={(e) => createTeam(e)}>
                    Create Team
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
          {userIsPending || (teamIsPending && <div>Loading...</div>)}
          {userError || (teamError && <div>{userError || teamError}</div>)}
        </div>
      </div>
    </>
  );
}
