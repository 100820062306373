import { React, useEffect, useState } from "react";
import NewsItem from "../../components/newsItem/newsItem";
import { useDocument } from "../../hooks/useDocument";
import { useDatabase } from "../../hooks/useDatabase";
import { useAuthContext } from "../../hooks/useAuthContext";

//FIXME: fix spamming of multiple event changes.

export default function NewsFeed({ setLoading }) {
  document.title = "OtterBox - Newsfeed";

  const { getAllDocuments, response } = useDatabase("newsFeed");
  const { document: teamData } = useDocument("aggregatedData", "teams");

  const [newsItems, setNewsItems] = useState([]);
  const { user } = useAuthContext();

  const fetchAllNews = async () => {
    // TODO: at some point make newsfeed only pull data relating to users team(s). might not be possible with RTD. maybe functions to build a bespoke newsfeed?
    // const myTeams = user.profileData.teams;
    // const myTeamPlayers = await myTeams
    //   .map((teamId) => teamData?.teams?.find((team) => team.id === teamId)?.members || [])
    //   .flat();
    // console.log(myTeams, myTeamPlayers);
    const allDocs = await getAllDocuments();
    allDocs.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
    setNewsItems(allDocs);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllNews();
  }, [teamData, user]);

  const { document: users } = useDocument("aggregatedData", "users");
  const userProfiles = {};
  users?.users?.forEach((user) => {
    userProfiles[user.id] = user;
  });

  const [visibleItems, setVisibleItems] = useState(15);
  const loadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 15); // Load 15 more items
  };

  return (
    <div className="pb-3">
      {users && newsItems && <NewsItem data={newsItems.slice(0, visibleItems)} profiles={userProfiles} />}
      {visibleItems < newsItems?.newsItems?.length && (
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary" onClick={loadMore}>
            Load more...
          </button>
        </div>
      )}
    </div>
  );
}
