import React, { useRef, useEffect, useState } from "react";
import $ from "jquery";

export function PlacesAutocomplete({ setEventLocation, setEventTown, setVenueName, clearInput, setClearInput, venueName }) {
  const autocompleteInput = useRef(null);
  const [inputValue, setInputValue] = useState(venueName || "");

  useEffect(() => {
    setClearInput(false);
  }, []);
  useEffect(() => {
    if (venueName) {
      setInputValue(venueName); // Update the input if eventLocation changes
    }
  }, [venueName]);

  const handleChange = (value) => {
    setInputValue(value);
    setEventLocation(value);
  };

  useEffect(() => {
    const loadAutocomplete = () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput.current, {
          types: ["establishment"],
          componentRestrictions: { country: "GB" },
        });

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          const postalTown = place.address_components.find((component) => component.types.includes("postal_town"));

          if (place && place.formatted_address) {
            setEventLocation(place.formatted_address);
            setEventTown(postalTown?.short_name);
            setVenueName(place.name);
          } else {
            setEventLocation("");
          }
          $(".pac-container").remove();
        });
      }
    };

    loadAutocomplete();

    // Clear the input when the clearInput prop changes
    if (clearInput) {
      autocompleteInput.current.value = "";
      setEventLocation("");
    }
  }, [setEventLocation, clearInput]);

  return (
    <input
      ref={autocompleteInput}
      type="text"
      className="form-control"
      value={inputValue}
      onChange={(e) => handleChange(e.target.value)}
      placeholder="Event Location"
    />
  );
}
