import { React, useState, useRef, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useUpdateProfile } from "../../hooks/useUpdateProfile";
import { useDocument } from "../../hooks/useDocument";
import { Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useLogout } from "../../hooks/useLogout";
import Equipment from "../../components/profile/equipment/Equipment";
import defaultProfile from "../../assets/defaultProfile.webp";
import TextDivider from "../../components/textDivider/TextDivider";
import { toTitleCase } from "../../utils/toTitleCase";
import Spacer from "../../components/spacer/spacer";
import { ToastContainer, toast } from "react-toastify";

//TODO add pills for global admin and boost user.

export default function Profile({ setLoading, newUser = null }) {
  const { logout } = useLogout();
  const { user, authIsReady } = useAuthContext();
  const [firstName, setFirstName] = useState("");
  const [surName, setSurName] = useState("");
  const [alias, setAlias] = useState("");
  const [nameEdit, setNameEdit] = useState(false);
  const [email, setEmail] = useState("");
  const [emailEdit, setEmailEdit] = useState(false);
  const [pendingTeams, setPendingTeams] = useState([]);
  const [confirmedTeams, setConfirmedTeams] = useState([]);
  const [teamsEdit, setTeamsEdit] = useState(false);
  const [showReauthForm, setShowReauthForm] = useState(false); // Reauth form flag
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [password, setPassword] = useState(""); // Reauth password
  const [image, setImage] = useState(null);
  const [cropModalShow, setCropModalShow] = useState(false);
  const navigate = useNavigate();
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showCreateProfileModal, setShowCreateProfileModal] = useState(false);
  const [showFindTeamModal, setShowFindTeamModal] = useState(false);
  const [newPrimaryTeam, setNewPrimaryTeam] = useState("");

  const profileId = useParams().id || newUser?.uid;
  const { document: userDoc, isPending: userIsPending } = useDocument("users", profileId);

  useEffect(() => {
    if (userIsPending) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userIsPending]);
  useEffect(() => {
    if (user && user.uid === profileId) {
      document.title = "OtterBox - My Profile";
    } else if (userDoc) {
      document.title = `OtterBox - ${userDoc.profileData.firstName} ${userDoc.profileData.surName}'s Profile`;
    }
  }, [user, userDoc, profileId]);

  const eventData = useDocument("aggregatedData", "events");
  const teamData = useDocument("aggregatedData", "teams");
  const filteredTeams = teamData?.document?.teams || [];
  const teamOptions = filteredTeams
    ?.filter((team) => !team.deleted)
    .map((team) => ({
      value: team.teamName,
      label: team.teamName,
    }));

  const filteredEvents = eventData?.document?.events
    ?.filter((event) => {
      const endDate = new Date(event.endDate);
      const today = new Date();
      return (
        endDate > today &&
        (event.players.going.map((profileId) => profileId.id).includes(profileId) || event.players.interested.includes(profileId))
      );
    })
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  const yourEvents = eventData?.document?.events?.filter((event) => {
    const endDate = new Date(event.endDate);
    const today = new Date();
    return (
      endDate > today &&
      (event.players.going.map((profileId) => profileId.id).includes(user.uid) || event.players.interested.includes(user.uid))
    );
  });
  const sameEvents = filteredEvents
    ?.filter((event) => yourEvents?.some((yourEvent) => yourEvent.id === event.id))
    .map((event) => event.id);
  const userData = userDoc?.profileData;
  const eventsData = userDoc?.attendance;

  useEffect(() => {
    if (!user.profileData) {
      setShowCreateProfileModal(true);
      return;
    } else {
      setShowCreateProfileModal(false);
    }
    if (newUser) {
      setShowFindTeamModal(true);
      return;
    }
  }, [user]);
  // console.log(eventsData);

  const {
    updateProfilePicture,
    updateProfile,
    updateEmail,
    error,
    isPending,
    crop,
    zoom,
    onCropComplete,
    setCrop,
    setZoom,
    deleteAccount,
    createProfile,
    deleteGoogleUser,
    updatePrimaryTeam,
  } = useUpdateProfile();

  const handleToggleCropModal = () => {
    setCropModalShow(!cropModalShow);
  };

  const handleToggleEditProfileModal = () => {
    setShowEditProfileModal(!showEditProfileModal);
    resetEditFlags();
  };
  const handleToggleCreateProfileModal = () => {
    setNameEdit(true);
    setShowCreateProfileModal(!showCreateProfileModal);
  };
  const handleToggleFindTeamModal = () => {
    setShowFindTeamModal(!showFindTeamModal);
  };

  const handleConfirmCrop = () => {
    handleToggleCropModal();
    updateProfilePicture(image);
  };

  const handleNameEdit = (e) => {
    e.preventDefault();
    if (nameEdit) {
      if (!firstName || !surName) {
        toast.warning("First name and surname are required");
        return;
      }
      setNameEdit(!nameEdit);
      let tcFirstName = toTitleCase(firstName);
      let tcSurName = toTitleCase(surName);
      let update = { firstName: tcFirstName, surName: tcSurName, alias };
      console.log(update);
      updateProfile({ update });
    }

    if (!nameEdit) {
      setNameEdit(!nameEdit);
      setFirstName(user.profileData?.firstName);
      setSurName(user.profileData?.surName);
      setAlias(user.profileData?.alias || "");
    }
  };
  const handleCreateProfile = (e) => {
    e.preventDefault();
    {
      if (!firstName || !surName) {
        return;
      }
      let update = { id: user.uid, firstName, surName, alias };
      console.log(update);
      createProfile({ update });
    }
  };

  const hiddenFileInput = useRef(null);
  const handleFileSelector = (e) => {
    document.getElementById("profileFileSelector").value = null;
    hiddenFileInput.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      setCropModalShow(true);
    }
  };

  const handleEmailEdit = (e) => {
    e.preventDefault();
    if (emailEdit) {
      // If the email is being saved, first show the reauth form
      setShowReauthForm(true);
    } else {
      setEmailEdit(!emailEdit);
      setEmail(user.email);
    }
  };

  const handleSetPrimaryTeam = (e) => {
    const teamId = filteredTeams.find((team) => team.teamName === e).id;
    setNewPrimaryTeam(teamId);
    const teamList = document.querySelectorAll(".team-list-item");
    teamList.forEach((team) => {
      console.log(team.textContent);
      if (team.textContent === e) {
        team.classList.add("bg-warning", "text-dark");
        team.classList.remove("bg-secondary", "text-light");
      } else {
        team.classList.remove("bg-warning", "text-dark");
        team.classList.add("bg-secondary", "text-light");
      }
    });
  };

  const handleTeamsEdit = (e) => {
    e.preventDefault();
    if (teamsEdit) {
      setTeamsEdit(!teamsEdit);
      if (!newPrimaryTeam) {
        return;
      }
      let update = {
        primaryTeamId: newPrimaryTeam,
      };
      updateProfile({ update });
    } else {
      setTeamsEdit(!teamsEdit);
      setPendingTeams(
        user.profileData?.pendingTeams?.map((teamId) => filteredTeams.find((team) => team.id === teamId).teamName) || [],
      );
      setConfirmedTeams(
        user.profileData?.teams?.map((teamId) => filteredTeams.find((team) => team.id === teamId).teamName) || [],
      );
    }
  };

  const handleReauthSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await updateEmail(email, password); // Perform reauthentication and email update
      setShowReauthForm(false); // Hide the reauth form after successful update
      setEmailEdit(false); // Close the email edit mode
    } catch (err) {
      console.error(err);
    }
  };

  const handleShowDeleteForm = async () => {
    setShowDeleteForm(!showDeleteForm);
  };

  const handleShowConfirmForm = async () => {
    setShowReauthForm(true);
  };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    if (!password) {
      toast.warning(
        `Please enter ${user.providerData[0].providerId === "google.com" ? "CONFIRM" : "your password"}  to delete your account.`,
      );
      return;
    }
    try {
      if (user.providerData[0].providerId === "google.com") {
        if (password !== "CONFIRM") {
          return toast.warning("Please enter CONFIRM to delete your account.");
        }
        const provider = new firebase.auth.GoogleAuthProvider();
        try {
          await firebase
            .auth()
            .currentUser.reauthenticateWithPopup(provider)
            .then(
              await deleteGoogleUser()
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                }),
            );
        } catch (err) {
          console.error(err);
        }
      } else {
        let res = await deleteAccount(password);
        if (res.success) {
          logout();
          navigate("/login");
        } else {
          toast.warning(res.error);
        }
        setShowEditProfileModal(false);
        setShowReauthForm(false);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const resetEditFlags = () => {
    setNameEdit(false);
    setEmailEdit(false);
    setShowReauthForm(false);
    setTeamsEdit(false); // Reset the reauth form flag
  };

  // if (loadingData) return <LoadingOverlay />;

  return (
    <>
      {" "}
      <Spacer height="48px" />
      {/* TODO want to merge these together to make maintenance a bit easier */}
      {/* large profile header */}
      <div className="row mx-1 mt-5 d-none d-sm-block">
        <Spacer height="24px" />
        <div className="card p-3">
          <div
            className="d-flex"
            style={{
              marginBottom: "-100px",
            }}
          >
            <div className="flex-shrink-1 profile-image-changer me-3 translate-middle-y">
              <img className="profile-image position-relative" src={userData?.photoURL || defaultProfile} />

              {profileId === user.uid && (
                <>
                  <button
                    className="btn btn-secondary position-relative bottom-0 start-100 translate-bottom-right rounded-circle btn-lg"
                    onClick={handleFileSelector}
                  >
                    <i className="bi bi-camera-fill fs-2 my-3"></i>
                  </button>
                  <div>
                    <input
                      type="file"
                      id="profileFileSelector"
                      ref={hiddenFileInput}
                      className="form-control mb-1 d-none"
                      onChange={handleFileChange}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="flex-grow-1">
              <h1>
                {/* {user.profileData?.firstName}{' '}
                {user.profileData?.alias && <i className="fw-light">&quot;{user.profileData?.alias}&quot;</i>}{' '}
                {user.profileData?.surName} */}
                {userData?.firstName} {userData?.alias && <i className="fw-light">&quot;{userData?.alias}&quot;</i>}{" "}
                {userData?.surName}
              </h1>
              <div className="d-flex flex-wrap">
                {userData?.primaryTeamId && (
                  <h5 onClick={() => navigate(`/team/${userData?.primaryTeamId}`)} role="button">
                    <span className="m-1 badge bg-light text-dark">
                      <i className="bi bi-star text-warning"></i>{" "}
                      {filteredTeams?.find((team) => team.id === userData?.primaryTeamId)?.teamName}
                    </span>
                  </h5>
                )}
                {userData?.teams
                  ?.filter((teamId) => teamId !== userData?.primaryTeamId)
                  .map((teamId) => {
                    const team = filteredTeams?.find((team) => team.id === teamId)?.teamName;
                    return (
                      <h5 key={teamId} onClick={() => navigate(`/team/${teamId}`)} role="button">
                        <span className="m-1 badge bg-light text-dark">{team}</span>
                      </h5>
                    );
                  })}
              </div>
            </div>
          </div>
          {profileId === user.uid && (
            <div className="d-flex justify-content-end">
              <button className="btn btn-secondary btn-sm" onClick={handleToggleEditProfileModal}>
                <i className="bi bi-pencil-square me-1"></i>Update Profile
              </button>
            </div>
          )}
        </div>
      </div>
      {/* small profile header */}
      <div className="row mx-1 mt-5 d-sm-none">
        <div className="card p-3" style={{ minHeight: "180px" }}>
          {profileId === user.uid && (
            <div className="d-flex justify-content-end position-absolute top-0 end-0 mt-2 me-3">
              <button className="btn btn-secondary ms-auto btn-sm" onClick={handleToggleEditProfileModal}>
                <i className="bi bi-pencil-square me-1"></i>Update Profile
              </button>
            </div>
          )}
          <div title="avatar" className="" style={{ marginTop: "-100px", marginBottom: "-24px", height: "190px" }}>
            <div className="flex-shrink-1 profile-image-changer-sm me-3">
              <img className="profile-image-sm position-relative" src={userData?.photoURL || defaultProfile} />

              {profileId === user.uid && (
                <>
                  <button
                    className="btn btn-secondary position-relative bottom-0 start-100 translate-bottom-right rounded-circle btn-lg"
                    onClick={handleFileSelector}
                  >
                    <i className="bi bi-camera-fill fs-2 my-3"></i>
                  </button>
                  <div>
                    <input
                      type="file"
                      id="profileFileSelector"
                      ref={hiddenFileInput}
                      className="form-control mb-1 d-none"
                      onChange={handleFileChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="">
            <h1>
              {userData?.firstName} {userData?.alias && <i className="fw-light">&quot;{userData?.alias}&quot;</i>}{" "}
              {userData?.surName}
            </h1>
            <div className="d-flex flex-wrap">
              {userData?.primaryTeamId && (
                <h5 onClick={() => navigate(`/team/${userData?.primaryTeamId}`)} role="button">
                  <span className="m-1 badge bg-light text-dark">
                    <i className="bi bi-star text-warning"></i>{" "}
                    {filteredTeams?.find((team) => team.id === userData?.primaryTeamId)?.teamName}
                  </span>
                </h5>
              )}
              {userData?.teams
                ?.filter((teamId) => teamId !== userData?.primaryTeamId)
                .map((teamId) => {
                  const team = filteredTeams?.find((team) => team.id === teamId)?.teamName;
                  return (
                    <h5 key={teamId} onClick={() => navigate(`/team/${teamId}`)} role="button">
                      <span className="m-1 badge bg-light text-dark">{team}</span>
                    </h5>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {user && profileId === user.uid && (!user?.profileData?.teams || user?.profileData?.teams?.length < 1) && (
        <div className="alert alert-info my-1 mx-1 mt-2">
          You are not a member of any teams! Visit the <Link to="/roster/teams">roster</Link> to join or create one.
        </div>
      )}
      {/* upcoming events */}
      <div className="row mt-1 mx-0">
        <div className="col-xs-12 col-lg-6 col-xl-5 p-1">
          <div className="card p-3">
            <h4>{profileId === user.uid ? "My Upcoming Events" : `${userData?.firstName}'s Upcoming Events`}</h4> <hr />
            {filteredEvents?.length === 0 && profileId === user.uid && (
              <div className="text-muted">
                You&apos;ve not joined any events! Head to the <Link to="/events">events calendar</Link> to join one.
              </div>
            )}
            {filteredEvents?.length === 0 && profileId !== user.uid && (
              <div className="text-muted">{userData?.firstName} has not signed up to any events.</div>
            )}
            {filteredEvents &&
              filteredEvents.map((event) => {
                const start = new Date(event.startDate).setHours(0, 0, 0, 0);
                const today = new Date().setHours(0, 0, 0, 0);
                const end = new Date(event.endDate).setHours(0, 0, 0, 0);

                const diffTime = Math.ceil(start - today);
                const diffTimeEnd = Math.ceil(end - today);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                const diffDaysEnd = Math.ceil(diffTimeEnd / (1000 * 60 * 60 * 24));

                const eventStatus = start <= today && end >= today ? "in progress" : diffDays > 0 ? "upcoming" : "ended";

                return (
                  <div
                    className="col"
                    key={event.id}
                    onClick={() =>
                      !event.privateEvent
                        ? navigate(`/events/${event.id}`)
                        : event.privateEvent && user.uid === profileId
                          ? navigate(`/events/${event.id}`)
                          : null
                    }
                    role="button"
                  >
                    <div className="card py-1 px-3 m-1">
                      <div className="d-flex">
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                          {user.uid !== profileId && event.privateEvent ? "Private event" : event.eventName}
                        </div>
                        {event.players?.interested.includes(user.uid) && (
                          <>
                            <i>&nbsp;</i>
                            <i
                              className="bi bi-question-circle-fill text-warning"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Interested in this event."
                            />
                          </>
                        )}
                        {!event.privateEvent && (
                          <small className="ms-auto" style={{ minWidth: "fit-content" }}>
                            {eventStatus === "upcoming"
                              ? `${new Date(event.startDate).toLocaleDateString("en-GB", { day: "numeric", month: "numeric", year: "2-digit" })} | ${diffDays} days`
                              : eventStatus}
                          </small>
                        )}
                        {event.privateEvent && user.uid === profileId && (
                          <small className="ms-auto" style={{ minWidth: "fit-content" }}>
                            {eventStatus === "upcoming"
                              ? `${new Date(event.startDate).toLocaleDateString("en-GB", { day: "numeric", month: "numeric", year: "2-digit" })} | ${diffDays} days`
                              : eventStatus}
                          </small>
                        )}
                        {user.uid !== profileId && sameEvents.includes(event.id) && (
                          <i
                            className="ms-2 bi bi-people-fill text-info"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="You are both going/interested in this event."
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {/* <div className="col-xs-12 col-lg-6 col-xl-8 p-1">
            <div className="card p-3">
              <Equipment />
            </div>
          </div> */}
      </div>
      <Modal show={cropModalShow} backdrop="static" onHide={handleToggleCropModal} id="cropModal">
        <Modal.Header closeButton />
        <Modal.Body style={{ height: "500px" }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <button className="btn btn-primary" onClick={() => handleConfirmCrop()}>
            Upload
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditProfileModal} backdrop="static" onHide={handleToggleEditProfileModal} id="editProfileModal">
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            Edit Profile
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2" id="editName">
            <div className="d-flex">
              <h3>Name</h3>
              {!nameEdit && (
                <span className="ms-auto btn btn-link" onClick={(e) => handleNameEdit(e)}>
                  edit
                </span>
              )}
              {nameEdit && (
                <span className="ms-auto btn btn-link" onClick={(e) => handleNameEdit(e)}>
                  save
                </span>
              )}
            </div>
            <div className="d-flex">
              {!nameEdit && (
                <div>
                  {user.profileData?.firstName} {user.profileData?.alias && `"${user.profileData?.alias}"`}{" "}
                  {user.profileData?.surName}
                </div>
              )}
              {nameEdit && (
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Alias"
                    value={alias}
                    onChange={(e) => setAlias(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={surName}
                    onChange={(e) => setSurName(e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row mb-2" id="editEmail">
            <div className="d-flex">
              <h3>Email</h3>
              {!emailEdit && (
                <span className="ms-auto btn btn-link" onClick={(e) => handleEmailEdit(e)}>
                  edit
                </span>
              )}
              {emailEdit && (
                <span className="ms-auto btn btn-link" onClick={(e) => handleEmailEdit(e)}>
                  save
                </span>
              )}
            </div>
            <div className="d-flex flex-column">
              {!emailEdit && <div>{user.email}</div>}
              {emailEdit && (
                <>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {showReauthForm && (
                    <form onSubmit={handleReauthSubmit} className="mt-2">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password to confirm"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button type="submit" className="btn btn-primary mt-2">
                        Confirm Email Update
                      </button>
                    </form>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="row mb-2" id="editTeams">
            <div className="d-flex">
              <h3>Teams</h3>
              {!teamsEdit && (
                <span className="ms-auto btn btn-link" onClick={(e) => handleTeamsEdit(e)}>
                  edit
                </span>
              )}
              {teamsEdit && (
                <span className="ms-auto btn btn-link" onClick={(e) => handleTeamsEdit(e)}>
                  save
                </span>
              )}
            </div>
            <div className="d-flex flex-column">
              {!teamsEdit && filteredTeams && (
                <>
                  <TextDivider text="Pending Teams" />
                  <div>
                    {user?.profileData?.pendingTeams
                      ?.map((teamId) => {
                        const team = filteredTeams?.find((team) => team.id === teamId);
                        return team ? team.teamName : undefined;
                      })
                      .filter((teamName) => teamName)
                      .join(", ")}
                  </div>
                  <TextDivider text="Confirmed Teams" />
                  <div>
                    {user?.profileData?.primaryTeamId && user?.profileData?.primaryTeamId && (
                      <span className="badge bg-secondary me-1">
                        <i className="bi bi-star text-warning"></i>{" "}
                        {filteredTeams?.find((team) => team.id === user?.profileData?.primaryTeamId)?.teamName}
                      </span>
                    )}
                    {user?.profileData?.teams &&
                      user?.profileData?.teams
                        ?.filter((teamId) => teamId !== user?.profileData?.primaryTeamId)
                        .map((teamId) => {
                          const team = filteredTeams?.find((team) => team.id === teamId);
                          return (
                            <span key={teamId} className="badge bg-secondary me-1 ">
                              {team?.teamName || undefined}
                            </span>
                          );
                        })}
                  </div>
                </>
              )}
              {teamsEdit && (
                <>
                  <TextDivider text="Pending Teams" />
                  <small className="text-center">(These teams are awaiting confirmation)</small>
                  <ul className="list-group list-hover mb-3">
                    {pendingTeams?.map((i) => (
                      <li className="text-align-start list-group-item" name={i} key={i}>
                        {i}
                      </li>
                    ))}
                  </ul>
                  <TextDivider text="Confirmed Teams" />
                  <small className="text-center">(Click a team to set as your primary team.)</small>
                  <div className="d-flex mb-2">
                    {confirmedTeams
                      ?.sort((a, b) => {
                        const aIsPrimary =
                          filteredTeams?.find((team) => team.teamName === a)?.id === user?.profileData?.primaryTeamId;
                        const bIsPrimary =
                          filteredTeams?.find((team) => team.teamName === b)?.id === user?.profileData?.primaryTeamId;
                        return bIsPrimary - aIsPrimary;
                      })
                      .map((i) => {
                        return (
                          <span
                            className="m-1 badge bg-secondary text-light team-list-item"
                            key={i}
                            onClick={() => handleSetPrimaryTeam(i)}
                          >
                            {filteredTeams?.find((team) => team.teamName === i)?.id === user?.profileData?.primaryTeamId ? (
                              <i className="bi bi-star text-warning me-1"></i>
                            ) : (
                              ""
                            )}
                            {i}
                          </span>
                        );
                      })}
                  </div>
                  <small className="text-center">(Visit the teams profile to leave the team.)</small>
                  {showReauthForm && (
                    <form onSubmit={handleReauthSubmit} className="mt-2">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password to confirm"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button type="submit" className="btn btn-primary mt-2">
                        Confirm Email Update
                      </button>
                    </form>
                  )}
                </>
              )}
            </div>
          </div>
          <hr />
          <div className="card">
            <button className="btn btn-danger" onClick={handleShowDeleteForm}>
              Delete Account
            </button>
          </div>
          {user.providerData[0].providerId === "google.com" && <p>You may need to reauthenticate with your Google account.</p>}
          {showDeleteForm && (
            <div className="card">
              Are you sure you want to delete your account? This action cannot be undone.
              <button className="btn btn-danger" onClick={handleShowConfirmForm}>
                Yes
              </button>
            </div>
          )}
          {showReauthForm && (
            <form onSubmit={handleDeleteAccount} className="mt-2">
              <input
                type={`${user.providerData[0].providerId === "google.com" ? "text" : "password"}`}
                className="form-control"
                placeholder={`${user.providerData[0].providerId === "google.com" ? "Type CONFIRM" : "Enter Password"}`}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className="btn btn-danger mt-2 me-auto ms-auto d-flex justify-content-center">
                Confirm Deletion
              </button>
            </form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={handleToggleEditProfileModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCreateProfileModal}
        centered
        backdrop="static"
        onHide={handleToggleCreateProfileModal}
        id="editProfileModal"
      >
        <Modal.Header>
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            Welcome to OtterBox!
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2" id="editName">
            <p>Let&apos;s finish creating your profile.</p>
            <div className="">
              <div className="">
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Alias"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                />
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Last Name"
                  value={surName}
                  onChange={(e) => setSurName(e.target.value)}
                />
              </div>
              <button className="btn btn-primary" onClick={(e) => handleCreateProfile(e)}>
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showFindTeamModal} centered backdrop="static" onHide={handleToggleFindTeamModal} id="editProfileModal">
        <Modal.Header>
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            Welcome to OtterBox!
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2" id="editName">
            <p>Otterbox works best when you are in a team.</p>
            <p>Let&apos;s head to the roster to find your team.</p>
            <div className="d-flex justify-content-center">
              <button className="btn btn-primary" onClick={() => navigate("/roster/teams")}>
                Let&apos;s go!
              </button>
              <button
                className="btn btn-primary-outline ms-2"
                onClick={() => {
                  handleToggleFindTeamModal();
                }}
              >
                Maybe later
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
