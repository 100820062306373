import React, { useEffect, useState } from "react";
import { messaging } from "../../config/config";
import { useFirestore } from "../../hooks/useFirestore";
import { useDocument } from "../../hooks/useDocument";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";

export default function NotificationSetup({ id }) {
  const { updateDocument } = useFirestore("users");
  const { document: userData } = useDocument("users", id);
  const [requestPerms, setRequestPerms] = useState(false);
  const [permsGranted, setPermsGranted] = useState(false);
  const [infoModal, setInfoModal] = useState(false);

  useEffect(() => {
    console.log("Notification permission:", Notification.permission);
    if (userData?.FCMTokens && Notification.permission === "default") {
      setRequestPerms(true);
    }
    if (Notification.permission === "granted" && !userData?.FCMTokens) {
      requestPermission();
      setRequestPerms(false);
    }
  }, [userData, Notification.permission]);

  useEffect(() => {
    if (permsGranted && (!userData.FCMTokens || !userData.FCMTokens.length)) {
      requestPermission();
      setRequestPerms(false);
    }
  }, [permsGranted]);

  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await messaging.getToken();
        const existingTokens = userData?.FCMTokens || [];
        const newTokens = [...new Set([...existingTokens, token])];
        // Store the FCM token in the user's data
        updateDocument(id, {
          FCMTokens: newTokens,
        });
      } else {
        console.error("Notification permission denied");
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };

  messaging.onMessage((payload) => {
    console.log("Message received. ", payload);
    // Optionally show a custom notification
    const { title, body } = payload.notification;
    // alert(`New Notification: ${title}\n${body}`);
    toast.info(`${title}\n${body}`, { autoClose: 0 });
  });

  if (requestPerms) {
    return (
      <>
        <div
          className="alert alert-info position-fixed bottom-0 alert-dismissible fade show w-100 text-center"
          style={{
            zIndex: 9999,
          }}
        >
          <span>
            Allow notifications so we can serve you{" "}
            <span className="link-primary" style={{ cursor: "pointer" }} onClick={() => setInfoModal(true)}>
              <u>important information?</u>
            </span>{" "}
            <button className="btn btn-success btn-sm ms-1 me-2" onClick={() => setPermsGranted(true)}>
              Allow
            </button>
            <button className="btn btn-outline-danger btn-sm" onClick={() => setPermsGranted(true)}>
              Deny
            </button>
          </span>
          <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
        </div>

        <Modal show={infoModal} onHide={() => setInfoModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Notification Types</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>These are the kinds of notifications you may receive. This list is not exhaustive, but we won&apos;t spam you.</p>
            <ul>
              <li>
                <strong>Team Updates:</strong> Team admins will get notified of team updates (join requests, etc).
              </li>
            </ul>
            <p>This list will grow as more features are added.</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => setInfoModal(false)}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return <div></div>;
}
